import React, { useState } from "react";
import Logo from "../images/AW_Logo_border.png";
import styled from "styled-components";
import MobileMenu from "./MobileMenu";
import Links from "./Links.js";

const LandingContainer = styled.div`
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .linksContainer {
    width: 200px;
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: none;
      color: #222;
      font-weight: 600;
      margin-right: 10px;

      &:hover {
        text-decoration: none;
        color: #ff9700;
        font-weight: 600;
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;

    img {
      width: 100px;
    }

    .nameContainer {
      margin-left: 20px;
    }

    h1 {
      margin: 0;
    }

    p {
      font-size: 1.3rem;
      margin: 0;
    }
  }

  .menuButton {
    display: none;
  }

  @media (max-width: 1200px) {
    .linksContainer {
      width: auto;
      display: flex;
      flex-direction: column;

      a {
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 900px) {
    .linksContainer {
      display: none;
    }

    .menuButton {
      display: block;
      position: fixed;
      right: 20px;
      top: 20px;
      padding: 10px 20px;
    }
  }

  @media (max-width: 600px) {
    .logoContainer {
      flex-direction: column;

      .nameContainer {
        margin-left: 0px;
      }
    }
  }
`;

const Landing = () => {
  const [showMenu, setShowMenu] = useState(false);

  const menuToggle = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
  };

  return (
    <LandingContainer>
      <section className="headerSection">
        <header>
          <div className="logoContainer">
            <img src={Logo} alt="Logo" />
            <div className="nameContainer">
              <h1>Alec Wertheimer</h1>
              <p>Web Developer & Designer</p>
            </div>
          </div>
          <div className="linksContainer">
            <Links />
          </div>
          <button className="menuButton" onClick={menuToggle}>
            Follow +{showMenu ? <MobileMenu /> : null}
          </button>
        </header>
        <p className="about">
          Building software is the perfect blend of creativity and problem
          solving, I was hooked as soon as I built my first website. Since then
          I have spent great deal of my time learning about, creating, and
          collaborating on some really kickass software, and other than the
          occasional failing test, have loved every second of it.
        </p>
      </section>
    </LandingContainer>
  );
};

export default Landing;
