import React from "react";
import styled from "styled-components";
import { useFetch } from "../utils/customHooks";
import PostCard from "./PostCard.js";
import "../App.css";

const BodyContainer = styled.div`
  h1 {
    margin: 1rem 0;
  }

  .posts-grid {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 900px) {
    .posts-grid {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Blog = () => {
  const posts = useFetch("https://dev.to/api/articles?username=ajwerth");

  if (!posts.response) {
    return (
      <BodyContainer>
        <div>Loading...</div>
      </BodyContainer>
    );
  }

  return (
    <BodyContainer>
      <section className="mainSection">
        <h1>From My Blog</h1>
        <p>I publish all of my posts on dev.to check it out.</p>
        <div className="posts-grid">
          <PostCard data={posts.response} />
        </div>
      </section>
    </BodyContainer>
  );
};

export default Blog;
