import React from "react";
import styled from "styled-components";
import Links from "./Links";

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    max-width: 5%;
  }

  .nameContainer {
    display: flex;
    align-items: center;

    h3 {
      color: #fff;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .nameLinks {
      width: 200px;
      margin: 10px 0;
      display: flex;
      justify-content: center;

      svg {
        margin: 0 5px;
      }

      a {
        font-size: 0.7rem;
        color: #fff;

        &:hover {
          color: #ff9700;
        }
      }
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <div className="nameContainer">
        <h3>Alec Wertheimer</h3>
        <div className="nameLinks">
          <Links />
        </div>
      </div>
    </FooterContainer>
  );
}

export default Footer;
