import React from "react";
import styled from "styled-components";
import Links from "./Links";

const MenuContainer = styled.div`
  display: flex;
  position: fixed;
  right: 20px;
  top: 85px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 3px solid #222;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 3px 3px #222;

  a {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MobileMenu = () => {
  return (
    <MenuContainer>
      <Links />
    </MenuContainer>
  );
};

export default MobileMenu;
