import React from "react";
import styled from "styled-components";
import "../App.css";

const Card = styled.div`
  width: 40%;
  background: #fff;
  box-shadow: 4px 4px 0 #222;
  border: 3px solid #222;
  border-radius: 4px;
  padding: 1rem;
  margin-right: 2rem;
  margin-bottom: 2rem;

  &:hover {
    cursor: pointer;
    background-color: #ff9700;
    border: 3px solid #222;
    box-shadow: 1px 1px #222;
  }

  h2 {
    margin: 1rem 0;
    padding-bottom: 2px;
  }

  @media (max-width: 900px) {
    width: auto;
    margin-right: 0;
  }
`;

const PostCard = (props) => {
  const { data } = props;

  if (data === undefined) {
    return null;
  }

  const RowRendered = data.map((item) => {
    return (
      <Card key={item.id}>
        <a href={item.url} className="blogPostTitle">
          <div className="cardHeader">
            <h2>{item.title}</h2>
          </div>
          <div className="cardBody"></div>
          <p>{item.description}</p>
        </a>
      </Card>
    );
  });

  return RowRendered;
};

export default PostCard;
