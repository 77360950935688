import React from "react";
import styled from "styled-components";

const ProjectsContainer = styled.div`
  .work-links {
    display: flex;

    button {
      cursor: pointer;
      margin-right: 1rem;
    }
  }

  .title {
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    .work-links {
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
`;

const Work = () => {
  return (
    <ProjectsContainer>
      <section className="mainSection">
        <div className="title">
          <h1>Work</h1>
          <p>A Few small Front-End Apps I've built.</p>
        </div>
        <h2>Apps</h2>
        <div className="work-links">
          <a href="https://github.com/Ajwerth/Redux-Starter" target="_blank">
            <button>
              <h3>Redux Starter</h3>
            </button>
          </a>
          <a
            href="https://codesandbox.io/s/alec-number-guess-bcpn2"
            target="_blank"
          >
            <button>
              <h3>Number Guess</h3>
            </button>
          </a>
          <a href="https://github.com/Ajwerth/rental-app" target="_blank">
            <button>
              <h3>Rental App</h3>
            </button>
          </a>
        </div>
      </section>
    </ProjectsContainer>
  );
};

export default Work;
