import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDev,
  faGithub,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";

const Links = () => {
  return (
    <>
      <a href="https://dev.to/ajwerth" id="el">
        <FontAwesomeIcon icon={faDev} size="2x" />
      </a>
      <a href="https://github.com/Ajwerth" id="el">
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </a>
      <a href="https://www.linkedin.com/in/awerth/" id="el">
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </a>
      <a href="https://twitter.com/AlecWertheimer" id="el">
        <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
      </a>
    </>
  );
};

export default Links;
