import { useState, useEffect } from "react";
import axios from "axios";

export const useFetch = (url, apiKey) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log(apiKey);
  console.log(url);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      axios
        .get(
          url,
          { credentials: "include" },
          {
            headers: {
              "Access-Control-Request-Headers": "*",
              "api-key": `${apiKey}`,
            },
          }
        )
        .then(function (response) {
          const json = response.data;
          setResponse(json);
          setIsLoading(false);
          console.log(json);
        })
        .catch(function (error) {
          setError(error);
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    };
    fetchData();
  }, [apiKey, url]);

  return { response, isLoading, error };
};
