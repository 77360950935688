import React, { useState, useEffect } from "react";
import "./App.css";
import styled from "styled-components";
import Work from "./components/Work";
import Blog from "./components/Blog";
import Landing from "./components/Landing";
import Footer from "./components/Footer";

const AppContainer = styled.div`
  margin: 5% 15%;

  @media (max-width: 900px) {
    margin: 15% 5%;
  }
`;

function App() {
  useEffect(() => {
    document.title = "Alec Wertheimer";
  }, []);

  return (
    <AppContainer>
      <Landing />
      <Work />
      <Blog />
      <Footer />
    </AppContainer>
  );
}

export default App;
